import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;
const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

const instanceFile = axios.create({
  baseURL: apiUrl,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
    withCredentials: true,
    mode: "no-cors",
  },
  responseType: "blob",
});
//instanceFile.defaults.headers["Access-Control-Allow-Origin"] = true;
instanceFile.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("tokenLog");

instance.defaults.headers["Access-Control-Allow-Origin"] = true;
instance.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("tokenLog");

export default {
	setToken(token) {
		instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
		instanceFile.defaults.headers.common['Authorization'] = 'Bearer ' + token;
	},
	loginAuth(authRequest) {
		return instance.post('auth', authRequest);
	},
	getJwtData() {
		return instance.get('auth');
	},
	getDocuments(body) {
		return instance.get('documentos',{
			params: {
				conPaginacion: body.pagination,
				paciente: body.patient,
				profesional: body.professional,
				obraSocialId: body.socialWork ? body.socialWork.id : undefined,
				estadoId: body.status ? body.status.id : null,
				regionalId: body.regional ? body.regional.id : undefined,
				numeroAutorizacion: body.auth,
				fechaPrimeraSesionDesde: body.dateFirstSessionFrom,
				fechaPrimeraSesionHasta: body.dateFirstSessionTo,
				fechaRegistroDesde: body.dateRegistrationFrom,
				fechaRegistroHasta: body.dateRegistrationTo,
				fechaPeriodoMes: body.month,
				fechaPeriodoAnio: body.year,
				fechaRegistroHasta: body.dateRegistrationTo,
				fechaRegistroDesde:  body.dateRegistrationFrom,
				tienePeriodo: body.withPeriod,
				pagina: body.page,
				cantidad: body.quantity,
				orderby: body.orderby
			}
		});
	},
	printDocumentSessions(id) {
		return instanceFile.get('documentos/' + id + '/sesiones');
	},
	imagesDownload(images) {
		return instanceFile.get(
			'documentos/' + images.documentoId + '/imagenes/' + images.imagenId
		);
	},
	updateSession(documentoId, autorizacionId, body) {
		return instance.put(
			'/documentos/' + documentoId + '/fecha-sesiones/' + autorizacionId,
			body
		);
	},
	getDocumentByPeriodId(id, professionalId) {
		let queryParams = '';
		if (professionalId) {
			queryParams += '/?profesionalId=' + professionalId;
		}
		return instance.get('periodos/' + id + '/documentos' + queryParams);
	},
	printSocialWorksPeriods(documentId, professionalId) {
		return instanceFile.get(
			'/periodos/' +
				documentId +
				'/profesionales/' +
				professionalId +
				'/obras-sociales/reporte'
		);
	},
	printPdfMaster(params){
		return instanceFile.get(`periodos/pdf`, {
			params: {
				obraSocialId: params.obraSocialId,
				planId: params.planId,
				mes: params.mes,
				anio: params.anio 
			}
		})
	},
	getPeriods(body) {
		return instance.get('periodos/detalles', {
			params: {
				mes: body.month,
				anio: body.year,
				profesional: body.professional,
				pagina: body.page,
				cantidad: body.quantity,
				estadoId: body.stateId,
				obraSocialId: body.socialWorkId
			}
		});
	},
	getAuditPeriods(body) {
		return instance.get('periodos/auditorias/documentos', {
			params: {
				obraSocialId: body.socialWorkId,
				mes: body.month,
				anio: body.year,
				profesionalId: body.professionalId,
				estadoId: body.stateId,
				pagina: body.pagina,
				cantidad: body.cantidad
			}
		});
	},
	getPeriodsForCrud(body){
		return instance.get('/periodos', {
			params: {
				mes: body.month,
				anio: body.year,
				profesional: body.professional,
				pagina: body.page,
				cantidad: body.quantity
			}
		});
	},
	getPeriodById(idPeriod) {
		return instance.get('/periodos/' + idPeriod);
	},
	patchPeriod(id, params) {
		return instance.patch('/periodos/' + id, params);
	},
	getRegionals(colegioId, obraSocialId) {
		if (colegioId !== null) {
			return instance.get('colegios/' + colegioId + '/cuentas');
		}
		return instance.get('obras-sociales/' + obraSocialId + '/cuentas/lista');
	},
	getSocialWorkById(obraSocialId, accountId) {
		return instance.get(`obras-sociales/${obraSocialId}`, {
			params: {
				cuentaId: accountId ?? null
			}
		});
	},
	updateAssingSocialWork(socialWorkId, params) {
		return instance.put(`obras-sociales/${socialWorkId}/cuentas`, params);
	},
	UpdateSocialWorkByAssociation(associationId,accountId, socialWorkId, params) {
		return instance.put(`colegios/${associationId}/cuentas/${accountId}/obras-sociales/${socialWorkId}`, params);
	},
	AssignSocialWorkByAssociation(associationId, socialWorkId, params) {
		return instance.put(`colegios/${associationId}/cuentas/obras-sociales/${socialWorkId}`, params);
	},
	deleteAssingSocialWork(socialWorkId) {
		return instance.delete(`obras-sociales/${socialWorkId}/cuentas`);
	},
	UpdateAmountSocialWork(associationId, socialWorkId, params){
		return instance.put(`colegios/${associationId}/cuentas/obras-sociales/${socialWorkId}/practicas/montos`, params);
	},
	assingInstructive(socialWorkId, instructive){
		return instance.put(`obras-sociales/${socialWorkId}/instructivos`, JSON.stringify(instructive))
	},
	getInstructiveById(socialWorkId){
		return instance.get(`obras-sociales/${socialWorkId}/instructivos`);
	},
	ReactivateAssignedPractice(socialWorkId, practiceId){
		return instance.patch(`obras-sociales/${socialWorkId}/practicas/${practiceId}`);
	},
	ReactivateAssignedSocialWorkAccount(socialWorkId){
		return instance.patch(`obras-sociales/${socialWorkId}/cuentas`);
	},
	getSocialWorks(params = {}) {
		return instance.get('obras-sociales', {
			params: {
				Asignado: params.asignadas ??  true,
				ObraSocialId: params.obraSocialId ?? null,
				Eliminada: params.dadasDeBaja ?? false,
				pagina: params.pagina,
				cantidad: params.cantidad,
				ConPaginacion: params.conPaginacion ?? true
			},
		});
	},
	GetSocialWorksByAssociation(associationId, params = {}) {
		return instance.get(`colegios/${associationId}/regionales/obras-sociales`, {
			params: {
				cuentaId: params.cuentaId ?? null,
				obraSocialId: params.obraSocialId ?? null,
				conPaginacion: params.conPaginacion ?? true,
				pagina: params.pagina ?? null,
				cantidad: params.cantidad ?? null,
				Confirmada: params.confirmadas ?? null
			},
		});
	},
	GetSocialWorkForSelect(params = {}) {
		return instance.get('obras-sociales/lista', {
			params: {
				ObraSocialId: params.obraSocialId ?? null,
				Asignado: params.asignadas ?? true,
				Eliminada: params.dadasDeBaja ?? false,
				Confirmada: params.confirmada ?? null
			},
		});
	},
	GetDiagnosisForSelect(texto) {
		return instance.get(`obras-sociales/diagnosticos`, {
			params: { texto: texto },
		});
	},
	GetDiagnosisByCode(codeDocument) {
		return instance.get(`obras-sociales/diagnosticos/${codeDocument}`);
	},
	getSocialworkByAccount(cuentaId) {
		return instance.get('cuentas/' + cuentaId + '/obras-sociales/lista');
	},
	AssignSocialWorkAccount(socialWorkId, params) {
		return instance.post(`obras-sociales/${socialWorkId}/cuentas`, params);
	},
	getSocialWorksPlan(idSocialWork) {
		return instance.get(
			'obras-sociales/' + idSocialWork + '/planes/lista'
		);
	},
	getPractices(idSocialWork, assign = true, removed = false, withPaginated = false, page = 1, loadForPage = 20) {
		return instance.get('obras-sociales/' + idSocialWork + '/practicas', {
			params: {
				obraSocialId: idSocialWork,
				Assigned: assign,
				removed: removed,
				page: page,
				quantity: loadForPage,
				WithPaginated: withPaginated
			}
		});
	},
	uploadImageDocument(id, formData) {
		return instance.post('documentos/' + id + '/imagenes', formData);
	},
	getDatePractice(body) {
		return instance.get(
			'documentos/fechas-sesiones?cantidadSesiones=' +
				body.quantity +
				'&fechaPrimerSesion=' +
				body.dateFirstSession
		);
	},
	postDocument(body) {
		return instance.post('documentos', body);
	},
	postManualDocument(body){
		return instance.post('documentos/manual', body);
	},
	postManualAuthorizationDocument(id, body) {
		return instance.post('documentos/' + id + '/fecha-sesiones/manual', body);
	},
	updateDocument(documentId,body) {
		return instance.put(`documentos/${documentId}`, body);
	},
	getByIdDocument(id) {
		return instance.get('documentos/' + id);
	},
	deleteDocument(id) {
		return instance.delete('documentos/' + id);
	},
	getZipPeriodList() {
		return instance.get('documentos/lista-zip');
	},
	createZipManually(period){
		return instance.get(`documentos/zip-manual`,{params: {periodo: period}});
	},
	downloadZipArchive(archiveName) {
		return instanceFile.get('documentos/zip', { params: { archiveName } });
	},
	getByDniPatient(params) {
		return instance.get(`/pacientes/${params.dni}`,{
			params: {
				obraSocialId: params.socialWorkId
			}
		});
	},
	ValidateAffiliate(params) {
		return instance.get(`/obras-sociales/${params.socialWorkId}/afiliados/estados`,{
			params: {
				numeroDocumento: params.dni,
				numeroAfiliado: params.affiliateNum
			}
		});
	},
	getByDocumentNumber(number) {
		return instance.get('medicos/' + number);
	},
	editPeriod(id, documents) {
		return instance.patch(`periodos/${id}/documentos`, documents);
	},
	showDocumentPeriod(body) {
		return instance.post('periodos/documentos', body);
	},
	getReturns(body= {}){

		return instance.get('/documentos/devoluciones', {
			params: {
				conPaginacion: body.pagination,
				paciente: body.patient,
				profesional: body.professional,
				obraSocialId: body.socialWork ? body.socialWork.id : undefined,
				regionalId: body.regional ? body.regional.id : undefined,
				numeroAutorizacion: body.auth,
				fechaPrimeraSesionDesde: body.dateFirstSessionFrom,
				fechaPrimeraSesionHasta: body.dateFirstSessionTo,
				fechaRegistroDesde: body.dateRegistrationFrom,
				fechaRegistroHasta: body.dateRegistrationTo,
				fechaPeriodoMes: body.month,
				fechaPeriodoAnio: body.year,
				fechaRegistroHasta: body.dateRegistrationTo,
				fechaRegistroDesde:  body.dateRegistrationFrom,
				tienePeriodo: body.withPeriod,
				pagina: body.page,
				cantidad: body.quantity,
				orderby: body.orderby
			}
		});
	},
	getHolidayList(DateFrom, DateTo) {
		let endpoint = '';
		if (DateFrom) endpoint += (endpoint ? '&' : '?') + 'fechaDesde=' + DateFrom;
		if (DateTo) endpoint += (endpoint ? '&' : '?') + 'fechaHasta=' + DateTo;

		return instance.get('feriados/lista' + endpoint);
	},
	GetAllAssociations(body = {nombre: null, provinciaId: null}) {
		return instance.get('colegios',{
			params: {
				nombre: body.nombre,
				provinciaId: body.provinciaId
			}
		});
	},
	GetPendingIntegrations(params){
		return instance.get("practicas/external/lista",{
			params: {obraSocialId: params.socialWorkId, confirmada: params.isConfirm }
		});
	},
	RemovePendingIntegrations(params){
		return instance.delete(`practicas/external/${params.integrationId}`);
	},
	postAssociations(body) {
		return instance.post('colegios', body);
	},
	putAssociation(associationId, body) {
		return instance.put(`/colegios/${associationId}`, body);
	},
	getAssociationById(colegioId) {
		return instance.get('colegios/' + colegioId);
	},
	deleteAssociation(associationId) {
		return instance.delete(`/colegios/${associationId}`);
	},
	getHoliday(id) {
		return instance.get('feriados/' + id);
	},
	postHoliday(body) {
		return instance.post('feriados', body);
	},
	putHoliday(body) {
		return instance.put('feriados', body);
	},
	deleteHoliday(id) {
		return instance.delete('feriados/' + id);
	},
	deleteImage(documentId, imageId) {
		return instance.delete(`documentos/${documentId}/imagenes/${imageId}`);
	},
	deleteSession(documentoId, autorizacionId) {
		return instance.delete(
			'/documentos/' + documentoId + '/fecha-sesiones/' + autorizacionId
		);
	},
	GetAccountsList(params) {
		return instance.get('cuentas/lista',{
			params: {
				nombre: params.accountName,
				colegioId: params?.association?.value ?? null
			}
		});
	},
	GetConfigParams(){
		return instance.get(`cuentas/parametros/lista`);
	},
	AddConfigParams(params){
		return instance.post(`cuentas/parametros`, params);
	},
	EditConfigParams(params){
		return instance.put(`cuentas/parametros`, params);
	},
	GetDescountParam(profesionalId){
		return instance.get(`cuentas/parametros/descuentos`, {
			params: {
				usuarioId: profesionalId
			}
		});
	},
	getAccountById(cuentaId) {
		return instance.get('cuentas/' + cuentaId);
	},
	PostAccount(params) {
		return instance.post('cuentas', params);
	},
	PutAccount(accountId, params) {
		return instance.put('cuentas/' + accountId, params);
	},
	DeleteAccount(id) {
		return instance.delete('cuentas/' + id);
	},
	postNewExport(body) {
		return instanceFile.get('/periodos/excel', {
			params: {
				obraSocialId: body.obraSocialId,
				mes: body.mes,
				anio: body.anio,
				mostrarTodo: body.mostrarTodo,
				cuentaId: null,
				socialWorkPlanId: body.socialWorkPlanId,
			}
		});
	},
	exportExcelForAssociation(body) {
		return instanceFile.get('/periodos/excel', {
			params: {
				mes: body.mes,
				anio: body.anio,
				socialWorkPlanId: body.socialWorkPlanId,
				cuentaId: body.cuentaId,
				obraSocialId: body.obraSocialId,
			}
		});
	},
	exportExcelByProfessionalId({anio, mes, professionalId, id}) {
		return instanceFile.get('/periodos/profesionales/excel',{
			params: {
				anio,
				mes,
				professionalId,
				id
			}
		});
	},
	getProvinces(provinciaId){
		return instance.get('/provincias',{
			params: {
				provinciaId: provinciaId
			}
		});
	},
	getDataCurrentUser() {
		return instance.get('/me');
	},
	patchUpdatePassword(body) {
		return instance.patch('/usuarios', body);
	},
	getUsersList(body) {
		return instance.get('usuarios/lista', {
			params: {
				profesional: body.profesional,
				pagina: body.pagina,
				cantidad: body.cantidad,
				dadosDeBaja: body.dadosDeBaja ?? false,
				obraSocialId: body.obraSocialId ?? null,
				planObraSocialId: body.planObraSocialId ?? null,
				conPaginacion: body.conPaginacion ?? false,
				rolId: body.rolId
			},
		});
	},
	GetUserById(profesionalId){
		return instance.get(`usuarios/${profesionalId}`);
	},
	PostUser(params) {
		return instance.post('/usuarios', params);
	},
	UpdateUser(profesionalId, params) {
		return instance.put(`usuarios/${profesionalId}`, params);
	},
	updatePassUser(id, body){
		return instance.patch(`/usuarios/${id}/password`,body);
	},
	ReactivateUser(id){
		return instance.patch(`/usuarios/${id}`);
	},
	GetAccountSummaryByUserId(usuarioId){
		return instance.get(`usuarios/${usuarioId}/resumenes-cuentas`);
	},
	GetConditionIVA(){
		return instance.get("condiciones-iva");
	},
	lowerUser(idUser) {
		return instance.delete('/usuarios/' + idUser);
	},
	getTypeRoles() {
		return instance.get('/roles');
	},
	GetSegurosSelect() {
		return instance.get('/seguros');
	},
	GetFamily(profesionalId, params){
		return instance.get(`usuarios/${profesionalId}/familiares`, {
			params: {
				pagina: params.pagina,
				cantidad: params.cantidad
			}
		});
	},
	CreateFamily(profesionalId, params){
		return instance.post(`usuarios/${profesionalId}/familiares`, params);
	},
	UpdateFamily(profesionalId, familiarId, params){
		return instance.put(`usuarios/${profesionalId}/familiares/${familiarId}`, params);
	},
	DeleteFamily(profesionalId, familiarId){
		return instance.delete(`usuarios/${profesionalId}/familiares/${familiarId}`);
	},
	getUsersProfessional(body) {
		return instance.get('/usuarios/profesionales', {
			params: {
				mes: body.mes,
				anio: body.anio,
				colegioId: body.colegioId,
				cuentaId: body.cuentaId
			},
		});
	},
	sendEmailToRegional(body){
		return instance.post(`usuarios/email`, body);
	},
	getAccountsUsersList() {
		return instance.get('/cuentas/lista');
	},
	getOptionsBySocialWorkId(obraSocialId) {
		return instance.get('obras-sociales/' + obraSocialId + '/exportar-excel');
	},
	saveTemplateExcelById(body) {
		return instance.put('obras-sociales/' + body.id + '/exportar-excel', body);
	},
	exportIntegrationTxtFile(params){
		return instanceFile.get("integraciones/txt", {
			params: {
				mes: params.mes,
				anio: params.anio,
				obraSocialId: params.obraSocialId
			}
		});
	},
	exportIntegrationExcelFile(params){
		return instanceFile.get("integraciones/excel", {
			params: {
				mes: params.mes,
				anio: params.anio,
				obraSocialId: params.obraSocialId
			}
		});
	},
	updateVerifiedDocumentsBySW(periodId, professional, socialWorks) {
		return instance.put(
			'documentos/periodos/' +
				periodId +
				'/profesionales/' +
				professional +
				'/obras-sociales',
			socialWorks
		);
	},
	UpdateVerifiedDocuments(periodId, professionalId, doc) {
		return instance.put(`periodos/${periodId}/profesionales/${professionalId}/documentos`, doc);
	},
	postAuthorizationsDocument(id, body) {
		return instance.post(`documentos/${id}/fecha-sesiones`, body, {
			params: {tokenIntegracion: body.tokenIntegracion}
		});
	},
	postLiquidation(body){
		return instance.post('liquidaciones', body);
	},
	putSettlement(id,body){
		return instance.put(`liquidaciones/${id}`, body);
	},
	getSettlements(body){
		return instance.get('liquidaciones', {
			params: {
				Name: body.nombre,
				Date: body.fecha,
				pagina: body.pagina,
				cantidad: body.cantidad
			}
		});
	},
	deleteSettlement(id){
		return instance.delete(`liquidaciones/${id}`);
	},
	getSettlementById(id){
		return instance.get(`liquidaciones/${id}`);
	},
	GetNameForLiquidationByDate(dateSelected){
		return instance.get("liquidaciones/nombres", {
			params: {
				fecha: dateSelected
			}
		})
	},
	GetBillForLiquidation(liquidationId, pendingBills){
		return instance.get(`liquidaciones/${liquidationId}/facturas`,{
			params: {
				facturasPendientes: pendingBills
			}
		});
	},
	CreateLiquidation(params){
		return instance.post("liquidaciones", params);
	},
	FinalizeLiquidation(liquidationId) {
		return instance.patch(`/liquidaciones/${liquidationId}`);
	},
	GetLiquidationSummaryForProfessional(liquidationId){
		return instance.get(`liquidaciones/${liquidationId}/profesionales/resumenes`);
	},
	GetLiquidationDetailsForProfessional(liquidationId, profesionalId){
		return instance.get(`liquidaciones/${liquidationId}/profesionales/${profesionalId}`);
	},
	GetLiquidationDetails(liquidationId){
		return instance.get(`liquidaciones/${liquidationId}/detalles`);
	},
	GetLiquidationSummaryForSocialWork(liquidationId){
		return instance.get(`liquidaciones/${liquidationId}/obras-sociales/resumenes`);
	},
	GetLiquidationById(liquidationId){
		return instance.get(`liquidaciones/${liquidationId}`);
	},
	GetLiquidationByAnio(anio){
		return instance.get(`liquidaciones/lista`, {
			params: { anio }
		});
	},
	GetExportLiquidation(liquidationId, bancoId){
		return instance.get(`liquidaciones/${liquidationId}/exportar`, {
			params: { bancoId }
		});
	},
	GetSummaryBalanceExport(liquidationId){
		return instanceFile.get(`liquidaciones/${liquidationId}/saldos-a-depositar`);
	},
	GetSummaryLiquidationExport(liquidationId){
		return instanceFile.get(`liquidaciones/${liquidationId}/resumen`);
	},
	GetSummaryProfesionales(liquidationId){
		return instanceFile.get(`liquidaciones/${liquidationId}/reporte-profesional`);
	},
	GetSummaryObraSociales(liquidationId){
		return instanceFile.get(`liquidaciones/${liquidationId}/reporte-obras-sociales`);
	},
	GetSummarySocialWorkPeriod(liquidationId){
		return instanceFile.get(`liquidaciones/${liquidationId}/obras-sociales-periodo`);
	},
	GetSummaryProfitRetention(liquidationId, profesionalId) {
		return instanceFile.get(`liquidaciones/${liquidationId}/retencion-ganancia`, {
			params: {
				profesionalId: profesionalId
			}
		});
	},
	GetPositiveOrNegativeBalanceReport(isPositive) {
		return instanceFile.get(`usuarios/saldos`, {
			params: {
				esPositivo: isPositive
			}
		});
	},
	GetSocialWorkWithholdingsReport(params) {
		return instanceFile.get(`usuarios/retenciones-obra-social`, {
			params: {
				obraSocialId: params.socialWorkId,
				ordenarPor: params.orderBy,
				esAscendente: params.isAscendent
			}
		});
	},
	GetUnpaidBillsReport(params) {
		return instanceFile.get(`facturas/impagas`, {
			params: {
				edadFactura: params.billAge,
				origenDatos: params.dataSource,
				obraSocialId: params.socialWorkId
			}
		});
	},
	GetBillsPerPeriodReport(params) {
		return instanceFile.get(`facturas/periodos`, {
			params: {
				mes: params.mes,
				anio: params.anio,
			}
		});
	},
	GetNexClosingDate(period){
		return instance.get(`periodos/cierre`,{
			params: {
				mes: period.mes,
				anio: period.anio
			}
		});
	},
	GetReturnsCount(){
		return instance.get(`estadisticas/devoluciones/profesionales`);
	},
	GetDataOfPeriod(period, stateOfDocument){
		return instance.get(`estadisticas/periodos/profesionales`,{
			params: {
				mes: period.mes,
				anio: period.anio,
				estadoId: stateOfDocument,
			}
		});
	},
	GetPatientInfo(){
		return instance.get(`estadisticas/periodos/profesionales/documentos`);
	},
	GetTotalSessions(){
		return instance.get(`estadisticas/periodos/profesionales/sesiones`);
	},
	CreateSocialWorkWithPlan(socialWorkPostRequest){
		return instance.post(`obras-sociales`, socialWorkPostRequest);
	},
	UpdateSocialWork(idSocialWork, bodyParams){
		return instance.put(`obras-sociales/${idSocialWork}`,bodyParams);
	},
	ReactivateSocialWork(idSocialWork){
		return instance.patch(`obras-sociales/${idSocialWork}`);
	},
	DeleteSocialWork(socialWorkId){
		return instance.delete(`obras-sociales/${socialWorkId}`);
	},
	UpdatePlanForSocialWork(socialWorkPlanId,socialWorkId, bodyParams){
		return instance.put(`obras-sociales/${socialWorkId}/planes/${socialWorkPlanId}`, bodyParams);
	},
	CreatePlanForSocialWork(socialWorkId,bodyParams){
		return instance.post(`obras-sociales/${socialWorkId}/planes`, bodyParams);
	},
	DeletePlanForSocialWork(idSocialWorkPlan, idSocialWork){
		return instance.delete(`obras-sociales/${idSocialWork}/planes/${idSocialWorkPlan}`);
	},
	GetAllPractices(filterObj){
		return instance.get(`practicas/lista`,{
			params: {
				SocialWorkId: filterObj.obraSocialId,
				removed: filterObj.removed || false,
				pagina: filterObj.page || 1,
				cantidad: filterObj.quantity || 20,
				withPaginated: filterObj.withPaginate || false
			}
		});
	},
	CreatePracticeWithOs(bodyParams){
		return instance.post(`practicas`, bodyParams);
	},
	UpdatePracticeWithOs(practiceId, bodyParams){
		return instance.put(`practicas/${practiceId}`,bodyParams);
	},
	DeletePractice(practiceId){
		return instance.delete(`practicas/${practiceId}`);
	},
	ReactivatePractice(practiceId){
		return instance.patch(`practicas/${practiceId}`);
	},
	GetReturnsCountAdmin(){
		return instance.get(`estadisticas/devoluciones/cuentas`);
	},
	UpdateAssignedPractice(idSocialWork, practiceId) {
		return instance.patch(`obras-sociales/${idSocialWork}/practicas/${practiceId}`);
	},
	AssignPracticeForOs(idSocialWork, bodyParams){
		return instance.post(`obras-sociales/${idSocialWork}/practicas`,bodyParams);
	},
	UpdatePracticeForOs(idSocialWork,idPractice,bodyParams){
		return instance.put(`obras-sociales/${idSocialWork}/practicas/${idPractice}`,bodyParams);
	},
	DeleteAssignedPractice(idSocialWork, idPractice){
		return instance.delete(`obras-sociales/${idSocialWork}/practicas/${idPractice}`);
	},
	GetAmountForPractices(obraSocialId, cuentaId){
		return instance.get(`obras-sociales/${obraSocialId}/practicas/montos`, {
			params: {
				cuentaId: cuentaId
			}
		});
	},
	GetHistorialAmount(obraSocialId, practicaObraSocialId, cuentaId){
		return instance.get(`obras-sociales/${obraSocialId}/practicas/${practicaObraSocialId}/montos`,{
			params: {
				cuentaId: cuentaId ?? null
			}
		});
	},
	AssingAmountForPractices(idSocialWork, params){
		return instance.post(`obras-sociales/${idSocialWork}/practicas/montos`, params);
	},
	DeleteAmountForPractices(idSocialWork, idPracticeSocialWorkAccount, idAmount){
		return instance.delete(`obras-sociales/${idSocialWork}/practicas/${idPracticeSocialWorkAccount}/montos/${idAmount}`);
	},
	ConfirmOsByAssociation(associationId, params){
		return instance.patch(`colegios/${associationId}/cuentas/obras-sociales`, params);
	},
	GetDescounts(params){
		return instance.get('/descuentos/lista', {
			params: {
				profesionalId: params.profesional.value,
				fechaDesde: params.fechaDesde,
				fechaHasta: params.fechaHasta,
				pagina: params.pagina,
				cantidad: params.cantidad
			}
		});
	},
	CreateDescount(params){
		return instance.post(`/descuentos`,params);
	},
	EditDescount(id,params){
		return instance.put(`/descuentos/${id}`,params);
	},
	DeleteDescount(id){
		return instance.delete(`/descuentos/${id}`);
	},
	GetInstallments(params){
		return instance.get("/planes-cuotas/lista", {
			params: {
				profesionalId: params.profesional.value,
				pagina: params.pagina
			}
		})
	},
	CreateInstallment(params){
		return instance.post(`/planes-cuotas`,params);
	},
	EditInstallment(params){
		return instance.put(`/planes-cuotas/${params.id}`,params);
	},
	DeleteInstallment(id){
		return instance.delete(`/planes-cuotas/${id}`);
	},
	GetPreviewInstallment(params){
		return instance.get("planes-cuotas/cuotas", {
			params: {
				importe: params.importe,
				cantidadCuotas: params.cantidadCuotas,
				fecha: params.fecha
			}
		});
	},
	UpdateInstallmentSelected(installmentId,params){
		return instance.put(`planes-cuotas/${installmentId}/cuotas`,params);
	},
	GetAccreditations(params){
		return instance.get("/acreditaciones", {
			params: {
				profesionalId: params.profesional.value
			}
		})
	},
	CreateAccreditation(params){
		return instance.post("/acreditaciones", params);
	},
	EditAccreditation(params){
		return instance.put(`/acreditaciones/${params.id}`, params);
	},
	DeleteAccreditation(id){
		return instance.delete(`/acreditaciones/${id}`);
	},
	GetDiagnostics(params){
		return instance.get("/diagnosticos", {
			params: {
				obraSocialId: params.obraSocialId,
				pagina: params.pagina,
				busqueda: params.busqueda
			}
		});
	},
	GenerateEnvoices(params){
		return instance.post("periodos/facturas", params);
	},
	GetBills(params){
		return instance.get("facturas", {
			params: {
				obraSocialId: params.obraSocialId,
				fecha: params.fecha,
				estadoId: params.estadoFacturaId,
				refacturadoId: params.refacturadoId,
				pagina: params.pagina,
				cantidad: params.cantidad
			}
		})
	},
	GetBillsSelect(obraSocialId){
		return instance.get("facturas/lista", {
			params: {
				obraSocialId: obraSocialId
			}
		})
	},
	CreateBill(params){
		return instance.post("facturas", params);
	},
	EditBill(billId, params){
		return instance.put(`facturas/${billId}`, params);
	},
	DeleteBill(id){
		return instance.delete(`facturas/${id}`);
	},
	GetProfitWithHoldings(){
		return instance.get(`cuentas/retenciones-ganancia`);
	},
	CreateProfit(params){
		return instance.post("cuentas/retenciones-ganancia", params);
	},
	EditProfit(retencionParametroId, params){
		return instance.put(`cuentas/retenciones-ganancia/${retencionParametroId}`, params);
	},
	GetBillById(facturaId, conDebitoTemporal){
		return instance.get(`facturas/${facturaId}`, {
			params: {
				conDebitoTemporal: conDebitoTemporal
			}
		});
	},
	GenerateTxtSW(facturaId, obraSocialId) {
		return instanceFile.get(`facturas/${facturaId}/obras-sociales/reporte`, {
			params: { obraSocialId }
		});
	},
	GetBankList(params){
		return instance.get(`bancos`, {
			params: {
				id: params.idBanco,
				dadosDeBaja: params.dadosDeBaja,
				pagina: params.page,
				cantidad: params.pageSize
			}
		});
	},
	GetBankId(bankId){
		return instance.get(`bancos/${bankId}`);
	},
	GetBankSelectList(){
		return instance.get(`bancos/lista`);
	},
	CreateBank(params){
		return instance.post(`bancos`, params);
	},
	EditBank(bankId, params){
		return instance.put(`bancos/${bankId}`, params);
	},
	ReactivateBank(bankId){
		return instance.patch(`bancos/${bankId}`);
	},
	DeleteBank(bankId){
		return instance.delete(`bancos/${bankId}`);
	},
	GetReceiptList(params){
		return instance.get(`recibos`, {
			params: {
				fechaDesde: params.fechaDesde,
				fechaHasta: params.fechaHasta,
				numero: params.numero,
				profesionalId: params.profesionalId,
				obraSocialId: params.obraSocialId,
				facturaId: params.facturaId,
				eliminado: params.eliminado,
				pagina: params.pagina,
				cantidad: params.cantidad,
				entidadId: params.entidadId
			}
		});
	},
	GetReceiptById(receiptId){
		return instance.get(`recibos/${receiptId}`);
	},
	GetSuggestions(){
		return instance.get(`recibos/datos`);
	},
	GetPrintReceipt(receiptId){
		return instanceFile.get(`recibos/${receiptId}/reporte`);
	},
	CreateReceipt(params){
		return instance.post(`recibos`, params);
	},
	DeleteReceipt(receiptId){
		return  instance.delete(`recibos/${receiptId}`);
	},
	GetNewList(params){
		return instance.get("novedades", {
			params: {
				titulo: params.titulo,
				dadasDeBaja: params.dadasDeBaja,
				pagina: params.pagina,
				cantidad: params.cantidad
			}
		});
	},
	GetNewsForUsers(view){
		return instance.get("usuarios/novedades",{
			params: {
				leida: view
			}
		});
	},
	GetNewById(newId){
		return instance.get(`novedades/${newId}`);
	},
	CreateNew(params){
		return instance.post("novedades", params);
	},
	CheckAllNewsForUser(){
		return instance.put("usuarios/novedades");
	},
	UpdateNew(newId, params){
		return instance.put(`novedades/${newId}`, params);
	},
	ReactivateNew(newId){
		return instance.patch(`novedades/${newId}`);
	},
	DeleteNew(newId){
		return instance.delete(`novedades/${newId}`);
	}
};
